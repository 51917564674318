import * as React from 'react';
import Grid from '@mui/material/Grid';
import {
    FormHelperText, IconButton, InputLabel, MenuItem, Select, TextField
} from '@mui/material';
import Button from '@mui/material/Button';
import {Save, Add, Delete} from '@mui/icons-material';
import helpers from '../../helpers/common';
import projects from '../../models/projects';
import {useEffect, useState} from 'react';
import useRootHook from '../../hooks/useRoot';
import PropTypes from 'prop-types';
import paymethods from '../../models/paymethods';

ProjectMethodForm.propTypes = {
    project: PropTypes.object.isRequired,
    selectedMethod: PropTypes.object,
    excludeIDs: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired,
    closeForm: PropTypes.func.isRequired,
};

export default function ProjectMethodForm({project, selectedMethod, excludeIDs, refresh, closeForm}) {
    const [errors, setErrors] = useState({});
    const [methods, setMethods] = useState(null);
    const {setShowLoader, notify, defaultCurrency} = useRootHook();

    const customFeesToUnit = (customFees) => {
        return customFees.map(cf => ({lte: parseFloat(cf.lte) / defaultCurrency.subunit, fee: parseFloat(cf.fee)}));
    }

    // Prepare default fields
    const defaultFields = selectedMethod
        ? {
            ...selectedMethod,
            project_id: project.id,
            dispute_cost: parseFloat(selectedMethod.dispute_cost) / defaultCurrency.subunit,
            // Ensure custom_fees is an array if it exists; otherwise, an empty array
            custom_fees: Array.isArray(selectedMethod.custom_fees) ? customFeesToUnit(selectedMethod.custom_fees) : []
        }
        : {
            project_id: project.id,
            // By default, no custom fees
            custom_fees: []
        };

    const [fields, setFields] = useState(defaultFields);

    useEffect(() => {
        paymethods.index()
            .then((data) => {
                if (!data) return;
                // filter out already enabled methods
                setMethods(data.filter(method => !excludeIDs.includes(method.id)));
            })
            .catch(() => {});
    }, []);

    const handleSubmit = (ev) => {
        ev.preventDefault();

        const fieldsForSubmit = structuredClone(fields);

        // 1. Convert all custom_fees values to floats
        // 2. Sort by ascending lte
        const customFeesAsFloats = (fields.custom_fees || []).map(cf => ({
            lte: parseFloat(cf.lte) || 0,  // default to 0 if parseFloat fails
            fee: parseFloat(cf.fee) || 0
        }));
        customFeesAsFloats.sort((a, b) => a.lte - b.lte);

        // 3. Merge into new object
        fieldsForSubmit.custom_fees = customFeesAsFloats;

        const floatFields = ['dispute_cost', 'hold_amount', 'reserve_amount', 'fee'];
        const integerFields = ['hold_days', 'reserve_days'];

        floatFields.forEach((field) => {
            fieldsForSubmit[field] = parseFloat(fieldsForSubmit[field]);
        });
        integerFields.forEach((field) => {
            fieldsForSubmit[field] = parseInt(fieldsForSubmit[field]);
        })


        // 4. Validate using newFields
        const validationErrors = projects.methodValidator.addEditMethod(fieldsForSubmit);
        setErrors(validationErrors);

        // 5. Stop if there are errors
        if (Object.keys(validationErrors).length !== 0) return;

        // 6. Send to backend
        setShowLoader(true);
        const sendMethod = selectedMethod ? projects.editMethod : projects.addMethod;
        sendMethod(fieldsForSubmit)
            .then((result) => {
                setShowLoader(false);
                if (result) {
                    refresh();
                    closeForm();
                    notify(selectedMethod ? 'Method saved' : 'Method added');
                }
            })
            .catch(() => setShowLoader(false));
    };

    const handleChange = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);
        setErrors({...errors, [name]: undefined});

        setFields(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleBlur = (ev) => {
        const [target, value, name] = helpers.getFormEventData(ev);
        const newErrors = {...errors};
        const newFields = {...fields};

        // If there's a dedicated field validator in your project
        if (typeof projects.fieldValidator[name] === 'function') {
            projects.fieldValidator[name](newFields, newErrors);
        }

        setFields(newFields);
        setErrors(newErrors);
    };

    // ---------- CUSTOM FEES HANDLERS ----------

    // Add a new (lte, fee) pair
    const handleAddCustomFee = () => {
        setFields(prev => {
            const customFees = [...(prev.custom_fees || [])];
            customFees.push({ lte: '', fee: '' });
            return { ...prev, custom_fees: customFees };
        });
    };

    // Remove a custom fee row
    const handleRemoveCustomFee = (index) => {
        setFields(prev => {
            const customFees = [...(prev.custom_fees || [])];
            customFees.splice(index, 1);
            return { ...prev, custom_fees: customFees };
        });
    };

    // Handle updates to lte/fee fields inside custom_fees
    const handleCustomFeeChange = (index, fieldName, value) => {
        setErrors(prevErrors => ({ ...prevErrors, custom_fees: undefined })); // Clear any custom_fees errors if needed

        setFields(prev => {
            const customFees = [...(prev.custom_fees || [])];
            // Store as string for now (user might still be typing decimals, etc.)
            customFees[index][fieldName] = value;
            return { ...prev, custom_fees: customFees };
        });
    };

    if (!methods) return null;

    return (
        <React.Fragment>
            <Grid
                container
                spacing={2}
                component="form"
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                {/* Paymethod Selector */}
                <Grid item xs={12} md={4}>
                    <InputLabel>Select method</InputLabel>
                    <Select
                        fullWidth
                        error={!!errors.paymethod_id}
                        required
                        name="paymethod_id"
                        variant="standard"
                        id="paymethod_id"
                        value={fields.paymethod_id || ''}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        disabled={!!selectedMethod}
                    >
                        {methods.map((method) => (
                            <MenuItem key={`id-${method.id}`} value={method.id}>
                                {method.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {errors.paymethod_id && <FormHelperText>{errors.paymethod_id}</FormHelperText>}
                </Grid>

                {/* Dispute cost */}
                <Grid item xs={6} md={4}>
                    <InputLabel>Dispute cost</InputLabel>
                    <TextField
                        error={!!errors.dispute_cost}
                        value={fields.dispute_cost ?? ''}
                        name="dispute_cost"
                        id="dispute_cost"
                        required
                        variant="standard"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.dispute_cost && <FormHelperText>{errors.dispute_cost}</FormHelperText>}
                </Grid>

                {/* Hold days */}
                <Grid item xs={6} md={3}>
                    <InputLabel>Hold days</InputLabel>
                    <TextField
                        error={!!errors.hold_days}
                        value={fields.hold_days ?? ''}
                        name="hold_days"
                        id="hold_days"
                        required
                        variant="standard"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.hold_days && <FormHelperText>{errors.hold_days}</FormHelperText>}
                </Grid>

                {/* Hold amount % */}
                <Grid item xs={6} md={3}>
                    <InputLabel>Hold amount %</InputLabel>
                    <TextField
                        error={!!errors.hold_amount}
                        value={fields.hold_amount ?? ''}
                        name="hold_amount"
                        id="hold_amount"
                        required
                        variant="standard"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.hold_amount && <FormHelperText>{errors.hold_amount}</FormHelperText>}
                </Grid>

                {/* Rolling reserve days */}
                <Grid item xs={6} md={3}>
                    <InputLabel>Rolling reserve days</InputLabel>
                    <TextField
                        error={!!errors.reserve_days}
                        value={fields.reserve_days ?? ''}
                        name="reserve_days"
                        id="reserve_days"
                        required
                        variant="standard"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.reserve_days && <FormHelperText>{errors.reserve_days}</FormHelperText>}
                </Grid>

                {/* Rolling amount % */}
                <Grid item xs={6} md={3}>
                    <InputLabel>Rolling amount %</InputLabel>
                    <TextField
                        error={!!errors.reserve_amount}
                        value={fields.reserve_amount ?? ''}
                        name="reserve_amount"
                        id="reserve_amount"
                        required
                        variant="standard"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.reserve_amount && <FormHelperText>{errors.reserve_amount}</FormHelperText>}
                </Grid>

                {/* CUSTOM FEES SECTION */}
                <Grid item xs={12} mt={2}>
                    <InputLabel>Custom Fees</InputLabel>
                    <FormHelperText>
                        Add rules: "If amount &le; X => Fee Y". Sorted automatically by X.
                    </FormHelperText>
                </Grid>

                {fields.custom_fees?.map((cf, idx) => (
                    <React.Fragment key={idx}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label="If amount ≤"
                                value={cf.lte}
                                onChange={(e) =>
                                    handleCustomFeeChange(idx, 'lte', e.target.value)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                variant="standard"
                                fullWidth
                                label="Fee"
                                value={cf.fee}
                                onChange={(e) =>
                                    handleCustomFeeChange(idx, 'fee', e.target.value)
                                }
                            />
                        </Grid>
                        <Grid item xs={12} sm={4} display="flex" alignItems="center">
                            <IconButton
                                color="error"
                                onClick={() => handleRemoveCustomFee(idx)}
                                aria-label="remove custom fee row"
                            >
                                <Delete />
                            </IconButton>
                        </Grid>
                    </React.Fragment>
                ))}

                <Grid item xs={12} mt={1}>
                    <Button
                        startIcon={<Add />}
                        onClick={handleAddCustomFee}
                        variant="outlined"
                        size="small"
                    >
                        Add custom fee
                    </Button>
                </Grid>

                {/* DEFAULT FEE (Renamed from 'Fee') */}
                <Grid item xs={6} md={4} mt={3}>
                    <InputLabel>Default fee</InputLabel>
                    <TextField
                        error={!!errors.fee}
                        value={fields.fee ?? ''}
                        name="fee"
                        id="fee"
                        required
                        variant="standard"
                        fullWidth
                        onChange={handleChange}
                        onBlur={handleBlur}
                    />
                    {errors.fee && <FormHelperText>{errors.fee}</FormHelperText>}
                </Grid>

                {/* SUBMIT BUTTON */}
                <Grid container item spacing={3} sx={{mt: 2}}>
                    <Grid item xs={6}>
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            variant="outlined"
                            startIcon={<Save />}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    );
}